
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="addFileAttachments" autocomplete="off">
				<v-layout row wrap>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="file_attachments.file_attachment_path" type="textarea" :label="$store.getters.language.data.file_attachments.file_attachment_path" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex> -->

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-btn :color="img_file == null ? 'secondary' : 'teal'" :outlined="img_file == null" :loading="loading_btn" large style="width:100%" @click="openFile">
							upload
							<v-icon>fas fa-check</v-icon>
						</v-btn>
					</v-flex>
					<v-flex xs12 lg5 xl2 md3 sm4>
						<input type="file" @change="processFile" ref="file" style=" display:none" />
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="shop_items" v-model="file_attachments.shop_item_id" dense filled outlined item-text="shop_item_id" item-value="shop_item_id" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_id">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="file_attachments.user_id" dense filled outlined item-text="user_id" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.file_attachments.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" show-select v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="file_attachment_id">
								<template v-slot:[`item.file_attachment_id`]="{ item }">
									<div>
										<v-btn icon :to="'/file_attachments-list/'+item.file_attachment_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectFileAttachments(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteFileAttachmentsList">{{$store.getters.language.data.file_attachments.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.file_attachments.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.file_attachments.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteFileAttachments(selected_file_attachments.file_attachment_id)">
						{{$store.getters.language.data.file_attachments.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/file_attachments.request.js'
	export default {
		data() {
			return {
				img_file: null,
				file_attachments: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_file_attachments: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.file_attachments.file_attachment_path,
						align: 'start',
						sortable: true,
						value: 'file_attachment_path',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_id,
						align: 'start',
						sortable: true,
						value: 'shop_item_id',
					},
					{
						text: this.$store.getters.language.data.users.user_id,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'file_attachment_id',
					}
				],
			}
		},
		computed: {
			shop_items() {
				return this.$store.getters.shop_items_list
			},
			users() {
				return this.$store.getters.users_list
			},
		},
		mounted() {
			this.readFileAttachments();
		},
		methods: {

			openFile() {
				this.$refs.file.click();
			},
			processFile($event) {
				this.img_file = $event.target.files[0];
			},

			addFileAttachments() {
				this.loading_btn = true
				var formData = new FormData();
                formData.append('shop_item_id', this.file_attachments.shop_item_id);
                formData.append('user_id', this.file_attachments.user_id);
                formData.append('file_attachment_path', this.img_file);

				requests.createFileAttachments(formData).then(r => {
					if (r.status == 200) {
						this.file_attachments = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'FileAttachments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add FileAttachments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteFileAttachments(file_attachment_id) {
				requests.deleteFileAttachments(file_attachment_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.file_attachment_id != file_attachment_id
						})
						this.snackbar = {
							value: true,
							text: 'FileAttachments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteFileAttachmentsList() {
				let ids = this.selected_rows.map(m => m.file_attachment_id)
				requests.deleteFileAttachmentsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.file_attachment_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' FileAttachments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readFileAttachments() {
				this.loading = true
				requests.getAllFileAttachments().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectFileAttachments(i) {
				this.selected_file_attachments = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    